<template>
  <div>
    <!-- <div class="change d-flex">
      <div :class="isShow==true?'alllog':'gold'" @click="alllog">全部对战</div>
      <div :class="isShow==false?'alllog':'gold'" @click="mylog">我的对战</div>
    </div>-->
    <!-- 全部对战 -->
    <div class="all d-flex" v-show="isShow">
      <div class="pkItem" v-for="(item, index) in allPK" :key="index">
        <div class="top color-white weight">
          <div class="d-flex">
            <div v-show="item.endStatus == 1">已结束</div>
          </div>
        </div>
        <!-- 双人对战 -->
        <div class="main dl" v-show="item.joinType === '1'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-show="item.userList.length == 1"></div>
        </div>
        <!-- 三人对战 -->
        <div class="main dl" v-show="item.joinType === '2'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'i'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div
            class="user"
            v-for="(item, index) in three"
            :key="index + 2"
          ></div>
        </div>
        <!-- 四人对战 -->
        <div class="main dl" v-show="item.joinType === '3'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'ne'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-for="(item, index) in four" :key="index"></div>
        </div>
        <div class="round t-c color-white">{{ item.boxCount }}回合</div>
        <div class="money color-white t-c">
          <img
            src="../../../assets/images/pk/wmoney.png"
            style="width: 20px"
            alt
          />
          {{ item.payCoin }}
        </div>
        <div class="gifts">
          <div
            class="sw"
            v-for="(win, index) in item.boxImgs"
            :key="index + 'new'"
          >
            <img :src="win.img" alt />
          </div>
        </div>
        <div class="jion zt f-20" @click="joinPk(item.id)">观战</div>
      </div>
    </div>
    <!-- 我的对战 -->
    <div class="all d-flex" v-show="!isShow">
      <div class="pkItem" v-for="(item, index) in allPK" :key="index">
        <div class="top color-white weight">
          <div class="d-flex">
            <div v-show="item.endStatus == 1">已结束</div>
          </div>
        </div>
        <!-- 双人对战 -->
        <div class="main dl" v-show="item.joinType === '1'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-show="item.userList.length == 1"></div>
        </div>
        <!-- 三人对战 -->
        <div class="main dl" v-show="item.joinType === '2'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'i'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div
            class="user"
            v-for="(item, index) in three"
            :key="index + 2"
          ></div>
        </div>
        <!-- 四人对战 -->
        <div class="main dl" v-show="item.joinType === '3'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'ne'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-for="(item, index) in four" :key="index"></div>
        </div>
        <div class="round t-c color-white">{{ item.boxCount }}回合</div>
        <div class="money color-white t-c">
          <img
            src="../../../assets/images/pk/wmoney.png"
            style="width: 20px"
            alt
          />
          {{ item.payCoin }}
        </div>
        <div class="gifts">
          <div
            class="sw"
            v-for="(win, index) in item.winnerList"
            :key="index + 'new'"
          >
            <img :src="win.img" alt />
          </div>
        </div>

        <div class="jion zt f-20" @click="joinPk(item.id)">观战</div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="pk-h">
      <div :class="active == 1 ? 'active' : ''" @click="all">全 部 对 战</div>
      <div>擂 台 赛</div>
      <div :class="active == 2 ? 'active' : ''" @click="record">
        历 史 记 录
      </div>
    </div>
    <div class="pk-m">
      <div class="pk-mi" v-for="(item, index) in allPK" :key="index">
        <div class="mi-h">时间：{{ item.endTime }}z</div>
        <div class="pkp">
          <!-- 双人对战 -->
          <div class="mi-m" v-show="item.joinType === '1'">
            <div
              class="players"
              v-for="(item, index) in item.userList"
              :key="index"
            >
              <img
                v-if="item.portrait == null"
                src="@/assets/images/user/head.jpg"
                alt
              />
              <img v-else :src="item.portrait" alt />
            </div>
            <div class="user" v-show="item.userList.length == 1"></div>
          </div>
          <!-- 三人对战 -->
          <div class="mi-m" v-show="item.joinType === '2'">
            <div
              class="players"
              v-for="(item, index) in item.userList"
              :key="index + 'i'"
            >
              <img
                v-if="item.portrait == null"
                src="@/assets/images/user/head.jpg"
                alt
              />
              <img v-else :src="item.portrait" alt />
            </div>
            <div
              class="user"
              v-for="(item, index) in three"
              :key="index + 'zxa'"
            ></div>
          </div>
          <!-- 四人对战 -->
          <div class="mi-m" v-show="item.joinType === '3'">
            <div
              class="players"
              v-for="(item, index) in item.userList"
              :key="index + 'iz'"
            >
              <img
                v-if="item.portrait == null"
                src="@/assets/images/user/head.jpg"
                alt
              />
              <img v-else :src="item.portrait" alt />
            </div>
            <div
              class="user"
              v-for="(item, index) in three"
              :key="index + 'zxa'"
            ></div>
          </div>
          <div class="mi-ing">
            <div class="tp">总价值:</div>
            <img src="@/assets/images/pk/jinbi.png" alt />
            <div>{{ item.payCoin }}</div>
          </div>
        </div>
        <div class="mi-jion">
          <div
            class="mijn"
            v-for="(itm, index) in item.boxImgs"
            :key="index"
            @click="joinPk(item.id)"
          >
            <img :src="itm.img" alt class="minji" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomList } from "../../../api";
import { getPklog, getUserInfo } from "../../../api/index";
import { getToken } from "../../../api/cookie";
import { mapState } from "vuex";
export default {
  data() {
    return {
      allPK: [],
      three: [],
      four: [],
      path: this.$route.path,
      isShow: true,
      userGold: 0,
      show: false,
      at: 1,
      active: 2,
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  mounted() {
    getUserInfo().then((res) => {
      this.userGold = res.data.user.bean;
    });
    getPklog().then((res) => {
      console.log(res.data);
      this.allPK = res.data.rows;
      this.allPK.reverse();
    });
  },
  methods: {
    all() {
      this.active = 1;
      this.$router.push({ name: "Pk" });
    },
    record() {
      this.active = 2;
      this.$router.push({ name: "pklog" });
    },
    //加入对战
    joinPk(id) {
      let token = getToken();
      if (!token) {
        this.$message("请先登录");
      } else {
        //判断房间满人没
        getRoomList(id).then((res) => {
          let joinType = res.data.data.ttFightAgainst.joinType;
          let roomPeople = res.data.data.users.length;
          //双人房
          if (joinType == "1") {
            //判断房间满人没
            if (roomPeople == 1) {
              //判断钱够不够
              let gold = Number(this.userGold);
              if (gold >= res.data.data.ttFightAgainst.payCoin) {
                //没满，加入对战
                this.$router.push(`/waitepk/${id}`);
              } else {
                this.$message({
                  message: "金币不足,请先充值",
                  type: "warning",
                });
              }
            } else {
              //满了，观战
              this.$router.push(`/watch/${id}`);
            }
          }
          //三人房
          else if (joinType == "2") {
            if (roomPeople < 3) {
              //没满
              let gold = Number(this.userGold);
              if (gold >= res.data.data.ttFightAgainst.payCoin) {
                this.$router.push(`/waitepk/${id}`);
              } else {
                this.$message({
                  message: "金币不足,请先充值",
                  type: "warning",
                });
              }
            } else {
              //满了，观战
              this.$router.push(`/watch/${id}`);
            }
          }
          //四人房
          else {
            if (roomPeople < 4) {
              //没满
              let gold = Number(this.userGold);
              if (gold >= res.data.data.ttFightAgainst.payCoin) {
                this.$router.push(`/waitepk/${id}`);
              } else {
                this.$message({
                  message: "金币不足,请先充值",
                  type: "warning",
                });
              }
            } else {
              //满了，观战
              this.$router.push(`/watch/${id}`);
            }
          }
        });
      }
    },
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {
        if (to.name == "allpk") {
          this.at = 1;
        } else if (to.name == "pklog") {
          this.at = 2;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.change {
  width: 200px;
  margin: 25px 20px;
  color: #fff;
  justify-content: space-around;
}

.gold {
  color: gold;
}

//电脑端
.all {
  width: 1070px;
  overflow: auto;
  margin: 10px auto;
  height: 286px;
  flex-wrap: wrap;

  .pkItem {
    width: 263px;
    height: 286px;
    border-top: 1px transparent;
    background: url("~@/assets/images/pk/pkimg.png") no-repeat;
    background-size: 100% 100%;
    flex: none;

    .top {
      width: 80%;
      margin: 10px auto;

      .loading {
        width: 20px;
        height: 20px;
        background: url("../../../assets/images/pk/loading.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .money img {
      vertical-align: middle;
    }

    .main {
      width: 200px;
      height: 40px;
      margin: auto;
      justify-content: center;

      .user {
        width: 36px;
        height: 36px;
        border: 1px solid white;
        border-radius: 50%;
        flex: none;
      }

      .players {
        width: 36px;
        height: 36px;
        flex: none;

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }

    .round {
      font-weight: none;
      margin: 8px;
    }

    .jion {
      width: 130px;
      height: 35px;
      margin: auto;
      text-align: center;
      line-height: 35px;
      color: #000;
      background: url("~@/assets/images/pk/jion.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 15px;
    }

    .gifts {
      width: 60%;
      height: 43px;
      margin: auto;
      overflow: auto;
      display: flex;
      align-items: center;

      .sw {
        width: 74px;
        height: 39px;
        overflow: hidden;
        margin: auto;

        img {
          width: 74px;
          height: 39px;
        }
      }
    }
  }
}

.dl {
  display: flex;
}

.pk-h {
  display: none;
}

.pk-m {
  display: none;
}

.pk-cr {
  display: none;
}

//手机端
@media (max-width: 550px) {
  .all {
    display: none;
  }

  .dl {
    display: flex;
  }

  .pk-h {
    height: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    div {
      flex: none;
      text-align: center;
      width: 30%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }

    .active {
      color: rgba(255, 240, 0, 1);
      border-bottom: 2px solid rgba(255, 240, 0, 1);
    }
  }

  .pk-m {
    width: 98%;
    height: 650px;
    margin: 8px auto;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .pk-mi {
      width: 94%;
      height: 200px;
      margin: 4px auto;
      background-image: url("../../../assets/images/pk/bg123.png");
      background-size: 100% 100%;

      .mi-h {
        width: 206px;
        height: 20px;
        margin-top: 14px;
        margin-left: 14px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mi-hl {
          width: 60px;
          height: 90%;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .mi-hr {
          font-size: 14px;
          color: #fff;
        }
      }

      .pkp {
        display: flex;
        justify-content: space-between;
        width: 90%;
        height: 80px;
        margin: 10px auto 0px;

        .mi-m {
          width: 170px;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-evenly;

          .players {
            flex: none;
            width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .user {
            flex: none;
            width: 40px;
            height: 40px;
            background-image: url("../../../assets/images/waitpk/join.png");
            background-size: 100% 100%;
          }
        }

        .mi-ing {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: rgba(255, 240, 0, 1);

          .tp {
            margin-left: 4px;
          }

          img {
            display: block;
            margin-left: 4px;
            height: 16px;
            width: 16px;
          }
        }
      }

      .mi-jion {
        width: 90%;
        height: 66px;
        margin: auto;
        display: flex;
        overflow: auto;

        .mijn {
          flex: none;
          width: 20%;
          height: 100%;
          margin-right: 10px;
          background-image: url("../../../assets/images/pk/bg444.png");
          background-size: 100% 100%;

          .minji {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
